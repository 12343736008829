import styled from "styled-components";

const Oferta = () => {
  return (
    <>
      <section>
        <StyledContainer className="oferta-container" id="oferta">
          <div className="flex">
            <h2>OFERTA</h2>
            <div className="line"></div>
          </div>
          <p>
            W naszej ofercie posiadamy dobrze wyposażony park maszyn, dzięki
            któremu jesteśmy w stanie zrealizować dla Państwa nawet te
            najbardziej skomplikowane zlecenia.
          </p>
          <p>
            Specjalizujemy się w pracach ziemnych, transporcie kruszyw na plac
            budowy, rozbiórkach, pracach drogowych, pracach melioracyjne oraz
            pracach brukarskich.
          </p>
        </StyledContainer>
      </section>
    </>
  );
};

export default Oferta;
const StyledContainer = styled.div`
margin-bottom: 5rem;
   


  .flex {
    display: flex;
    align-items: baseline;
    gap: 10px;
    margin-bottom: 2rem;
    h2 {
      color: #c9a227;
      font-size: 3rem;
    }
  }
  .line {
    width: 100%;

    border-bottom: 4px solid #c9a227;
  }
`;
