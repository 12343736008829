import { createGlobalStyle } from "styled-components";

const GlobalStyle = createGlobalStyle`
    


* {
    margin: 0;
    padding: 0;
    box-sizing: border-box;
    scrollbar-width: thin;
    scrollbar-color: rgba(235, 215, 41, 0.5) transparent;

   
}
body{
    font-family: 'Work Sans', sans-serif;
    background: #15110C;
   
}


img{
    max-width: 100%;
}


section {
    width: 80%;
    margin: 0 auto;
    @media (max-width: 768px) {
      width: 88%;
    
}

}

p{
    color: #fff;
    font-size: 1.5rem;
    margin-bottom: 2em;
    line-height: 125%;
    letter-spacing: 2px;
    
  
}
html{
    @media (max-width: 768px) {
    font-size: 70%;
}

*::-webkit-scrollbar {
  width: 5px;
}
*::-webkit-scrollbar-track {
  background: #252525;
}
*::-webkit-scrollbar-thumb {
  background-color: rgb(235, 215, 41);
  border-radius: 20px;
  border: transparent;
}
}


`;

export default GlobalStyle;
