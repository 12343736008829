import { Link } from "react-router-dom";
import styled from "styled-components";
import realizacje1 from "../img/realizacje1.jpg";
import realizacje2 from "../img/realizacje2.jpg";
const Realizacje = () => {
  return (
    <>
      <section>
        <StyledContainer className="oferta-container" id="realizacje">
          <div className="flex">
            <h2>REALIZACJE</h2>
            <div className="line"></div>
          </div>
          <p>
            W codziennej pracy staramy się realizować założone cele w możliwie
            najefektywniejszy sposób, dlatego od strony logistycznej zawsze
            jesteśmy przygotowani na najróżniejsze zlecenia.
          </p>

          <div className="grid-gallery">
            <picture>
              <source srcSet={realizacje1} media="(max-width: 800px)" />
            <img
              src={realizacje1}
              alt="zdjecie poglądowe z realizacj prac budowlanych firmy wo-kaz"
            />
            </picture>
            
            <img
              src={realizacje2}
              alt="zdjecie poglądowe z realizacj prac budowlanych firmy wo-kaz"
            />
          </div>
          <Link to="/galeria-realizacji">
            <button>Zobacz galerię</button>
          </Link>
        </StyledContainer>
      </section>
    </>
  );
};

export default Realizacje;

const StyledContainer = styled.div`
  margin-bottom: 3rem;
  padding-top: 3rem;
  .grid-gallery {
    display: grid;
    grid-template-columns: 1fr 1fr;
    grid-template-rows: 50vh;
    grid-gap: 1rem;
    margin-bottom: 3rem;
    align-content: center;

    img {
      height: 100%;
      width: 100%;
      object-fit: cover;
    }
  }

  button {
    border: none;
    text-decoration: none;
    color: #352f29;
    background: #c9a227;
    padding: 0.5em 1.25em;
    font-size: 1.5rem;
    font-weight: 700;
    cursor: pointer;
    transition: color 0.25s;
  }

  button:hover,
  button:focus {
    color: #c9a227;
    background: #352f29;
  }

  .flex {
    display: flex;
    align-items: baseline;
    gap: 10px;
    margin-bottom: 2rem;
    h2 {
      color: #c9a227;
      font-size: 3rem;
    }
  }

  .line {
    width: 100%;

    border-bottom: 4px solid #c9a227;
  }
`;
