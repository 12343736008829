import styled from "styled-components";
import { Link , animateScroll as scroll } from "react-scroll";
const Navbar = () => {
    return(
        <>
        <StyledContainer className="navbar-container">
            <ul className="list">
                <Link to="o-nas"
                smooth={true}
                duration={1000}>
                      <li>O nas</li>
                </Link>
                <Link to="oferta"
                smooth={true}
                duration={1000}>
                      <li>Oferta</li>
                </Link>
                <Link to="flota"
                smooth={true}
                duration={1000}>
                      <li>Flota</li>
                </Link>
                <Link to="realizacje"
                smooth={true}
                duration={1000}>
                      <li>Realizacje</li>
                </Link>
                <Link to="kontakt"
                smooth={true}
                duration={1000}>
                       <li>Kontakt</li>
                </Link>
            </ul>
        </StyledContainer>
        </>
    )
}

export default Navbar


const StyledContainer = styled.div`
    @media (max-width: 1138px) {
        display: none;

       
    }
    .list {
        display: flex;
        justify-content: space-between;
        gap: 40px;
        list-style: none;
        color: #fff;
        text-shadow: 4px 4px 4px rgba(0, 0, 0, 0.25);

        li{
            font-size: 1.7rem;
            font-weight: 500;
            cursor: pointer;
            transition: all 0.25s
           
        }


        @media (max-width: 1300px) {
            li {
                font-size: 1.1rem;
            }
        }

        li:hover{
            transform: scale(1.05);
            color: #c9a227
        }
        
    }
`