import styled from "styled-components";
import logo from "../img/logo.PNG";
import heroBg from "../img/hero-bg.jpg";
import Navbar from "./Navbar";
import BurgerMenu from "./BurgerMenu";

const Hero = () => {
  return (
    <>
      <header>
        <StyledHeader>
        <BurgerMenu />
          <div className="hero-wrapper">
            <div className="hero-content">
            
              <div className="flex">
                <img id="logo"  src={logo} alt="logo wo-kaz usługi transportowe"></img>
               
                <Navbar className="navbar" />
              </div>

              <h1>
                Budujemy <span>lepsze jutro </span>
              </h1>
            </div>
          </div>
        </StyledHeader>
      </header>
    </>
  );
};

export default Hero;

const StyledHeader = styled.div`
img.logo{
  width: 400px;
  height: auto;
}
  @media (max-width: 600px) {
    #logo {
    margin-left: -5rem;
  }
  }

  @media (max-width: 1138px) {
    #logo {
    margin-left: -3rem;
  }
  }
 


  .flex {
    position: absolute;
    display: flex;
    justify-content: space-evenly;
    align-items: center;
    width: 100%;
    img {
      width: 30rem;
    }
  }

  @media (max-width: 1138px) {
    .flex{
      width: 70%
    }
  }

  

  @media (max-width: 1300px) {
    .navbar {
      top: 25%;
    }
  }

  @media (max-width: 1138px) {
    .navbar {
      display: none;
    }
  }

  .hero-wrapper {
    min-height: 50vh;
    padding: 2rem;
    position: relative;
    display: flex;
    flex-direction: column;
    justify-content: space-between;
  }
  .hero-wrapper::before {
    content: "";
    background-image: url(${heroBg});
    background-size: cover;
    background-position: bottom 50% right 20%;
    opacity: 40%;
    position: absolute;
    top: 0px;
    right: 0px;
    bottom: 0px;
    left: 0px;
  }

  h1 {
    position: absolute;
    bottom: 50px;
    color: #fff;
    font-size: 3rem;
    font-weight: 700;
    left: 10%;
  }

  h1 span {
    background: #e7c218;
    color: #352f29;
    white-space: nowrap;
  }

  @media (max-width: 1138px) {
    .flex {
      justify-content: flex-start;
      margin-left: 30px;
    }
  }

  @media (min-width: 1138px) {
    .flex {
      left: -2%;
    }
  }
`;
