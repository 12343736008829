import styled from "styled-components";
import Hero from "../components/Hero";
import ONas from "../components/ONas";
import Oferta from "../components/Oferta";
import Flota from "../components/Flota";
import Realizacje from "../components/Realizacje";
import Kontakt from "../components/Kontakt";
import firstSectionBg from "../img/first-section.webp";
import secondSectionBg from "../img/second-section.webp";

import { Helmet } from "react-helmet-async";
const MainPage = () => {
  return (
    <>
      <Helmet>
        <title> Wo-Kaz - usługi budowlane </title>
        <meta
          name="description"
          content="Budujemy lepsze jutro. Oferujemy usługi w zakresie transportu i sprzedaży materiałów sypkich oraz wszelkich prac ziemnych. Posiadamy ponad 20 letnie doświadczenie. "
        />
        <link rel="canonical" href="https://wo-kaz.pl" />
        
      </Helmet>
      <Hero />
      <StyledFirstSection>
        <div className="first-section">
          <ONas />
          <Oferta />
          <Flota />
        </div>
      </StyledFirstSection>
      <StyledSecondSection>
        <div className="second-section">
          <Realizacje />
          <Kontakt />
        </div>
      </StyledSecondSection>
    </>
  );
};

export default MainPage;

const StyledFirstSection = styled.div`
  .first-section {
    position: relative;
  }

  .first-section::before {
    content: "";
    background-image: url(${firstSectionBg});
    z-index: -1;
    position: absolute;
    background-size: cover;
    background-position: center;
    opacity: 0.06;
    top: 0px;
    left: 0px;
    right: 0px;
    bottom: 0px;
  }
`;

const StyledSecondSection = styled.div`
  .second-section {
    position: relative;
  }

  .second-section::before {
    content: "";
    background-image: url(${secondSectionBg});
    position: absolute;
    z-index: -1;
    background-size: cover;
    background-position: center;
    opacity: 0.06;
    top: 0px;
    left: 0px;
    right: 0px;
    bottom: 0px;
  }
`;
