import styled from "styled-components";
import Karuzela from "./Karuzela";

const Flota = () => {
  return (
    <>
      <section>
        <StyledFlota className="flota-container" id="flota">
          <div className="flex">
            <h2>FLOTA</h2>
            <div className="line"></div>
          </div>
          <p>
            Mimo iż nasi ludzie stanowią o potencjale firmy, nie mógłby by on
            być w pełni wykorzystany gdyby nie nowoczesny park maszynowy jaki
            posiadamy. Prezentujemy wybrane pojazdy i maszyny, dzięki którym
            nasze zadanie wykonujemy sprawnie, szybko i skutecznie!
          </p>
          <Karuzela/>
        </StyledFlota>
      </section>
    </>
  );
};

export default Flota;

const StyledFlota = styled.div`

  .flex {
    display: flex;
    align-items: baseline;
    gap: 10px;
    margin-bottom: 2rem;
    h2 {
      color: #c9a227;
      font-size: 3rem;
    }
  }
  .line {
    width: 100%;

    border-bottom: 4px solid #c9a227;
  }
`;
