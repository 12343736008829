import styled from "styled-components";
import React, {useEffect, useRef } from "react";
import logo from "../img/logo.PNG";
import backgroundImage from "../img/first-section.jpg";
import { Link } from "react-router-dom";
import { Swiper, SwiperSlide } from "swiper/react";
import { Autoplay, Navigation } from "swiper";
import "swiper/css";
import "swiper/css/navigation";
import "../styles-galeria.css";
import { Helmet } from "react-helmet-async";

import realizacje1 from "../img/galeria-realizacji/realizacje1.jpg";
import realizacje2 from "../img/galeria-realizacji/realizacje2.jpg";
import realizacje3 from "../img/galeria-realizacji/realizacje3.jpg";
import realizacje4 from "../img/galeria-realizacji/realizacje4.jpg";
import realizacje5 from "../img/galeria-realizacji/realizacje5.jpg";
import realizacje6 from "../img/galeria-realizacji/realizacje6.jpg";
import realizacje7 from "../img/galeria-realizacji/realizacje7.jpg";
import realizacje8 from "../img/galeria-realizacji/realizacje8.jpg";
import realizacje9 from "../img/galeria-realizacji/realizacje9.jpg";
import realizacje10 from "../img/galeria-realizacji/realizacje10.jpg";

import realizacje1avif from "../img/galeria-realizacji/avif/realizacje1.avif"
import realizacje2avif from "../img/galeria-realizacji/avif/realizacje2.avif"
import realizacje3avif from "../img/galeria-realizacji/avif/realizacje3.avif"
import realizacje4avif from "../img/galeria-realizacji/avif/realizacje4.avif"
import realizacje5avif from "../img/galeria-realizacji/avif/realizacje5.avif"
import realizacje6avif from "../img/galeria-realizacji/avif/realizacje6.avif"
import realizacje7avif from "../img/galeria-realizacji/avif/realizacje7.avif"
import realizacje8avif from "../img/galeria-realizacji/avif/realizacje8.avif"
import realizacje9avif from "../img/galeria-realizacji/avif/realizacje9.avif"
import realizacje10avif from "../img/galeria-realizacji/avif/realizacje10.avif"

import close from "../img/clear-icon.svg";

const GaleriaRealizacji = () => {
  const swiperRef = useRef(null);

  const toSlide = (num) => {
    swiperRef.current?.swiper.slideTo(num);
  };

  useEffect(() => {
    let photos = document.getElementsByClassName("grid-photo");
    for (let i = 0; i < photos.length; i++) {
      photos[i].addEventListener("click", (e) => test(e));
    }
  });

  function test(e) {
    document.getElementById("modal").style.display = "flex";
    document.getElementById("galeria").style.opacity = "0.1";
    document.body.style.overflow = "hidden";
    toSlide(e.target.getAttribute("id") - 1);
    document.getElementById("button-scroll").style.display = "none";
  }

  function hideModal() {
    document.getElementById("modal").style.display = "none";
    document.getElementById("galeria").style.opacity = "1";
    document.body.style.overflow = "unset";
    document.getElementById("button-scroll").style.display = "block";
  }

  return (
    <>
      <Helmet>
        <title>Wo-Kaz - Galeria realizacji</title>
        <meta
          name="description"
          content="Obejrzyj zdjęcia z naszych dotychczasowych realizacji"
        />
        <link rel="canonical" href="https://wo-kaz.pl/galeria-realizacji" />
      </Helmet>
      <StyledContainer className="galeria-realizacji-container" id="galeria">
        <div className="galeria-wrapper">
          <div className="galeria-container">
            <div className="header">
              <Link to="/" className="link">
                <img className="logo" src={logo} alt="" />
              </Link>
              <Link to="/">
                <button href="#">Powrót</button>
              </Link>
            </div>
            <div className="flex">
              <h2>GALERIA NASZYCH REALIZACJI</h2>
              <div className="line"></div>
            </div>
            <div className="grid-galeria">
              <picture> 
                <source srcSet={realizacje1avif} id="1"/>
                <img className="grid-photo" id="1" src={realizacje1} alt="" />
              </picture>
              <picture> 
                <source srcSet={realizacje2avif} id="2"/>
                <img className="grid-photo" id="2" src={realizacje2} alt="" />
              </picture>
              <picture> 
                <source srcSet={realizacje3avif} id="3"/>
                <img className="grid-photo" id="3" src={realizacje3} alt="" />
              </picture>
              <picture> 
                <source srcSet={realizacje4avif} id="4"/>
                <img className="grid-photo" id="4" src={realizacje4} alt="" />
              </picture>
              <picture> 
                <source srcSet={realizacje5avif} id="5"/>
                <img className="grid-photo" id="5" src={realizacje5} alt="" />
              </picture>
              <picture> 
                <source srcSet={realizacje6avif} id="6"/>
                <img className="grid-photo" id="6" src={realizacje6} alt="" />
              </picture>
              <picture> 
                <source srcSet={realizacje7avif} id="7"/>
                <img className="grid-photo" id="7" src={realizacje7} alt="" />
              </picture>
              <picture> 
                <source srcSet={realizacje8avif} id="8"/>
                <img className="grid-photo" id="8" src={realizacje8} alt="" />
              </picture>
              <picture> 
                <source srcSet={realizacje9avif} id="9"/>
                <img className="grid-photo" id="9" src={realizacje9} alt="" />
              </picture>
              <picture> 
                <source srcSet={realizacje10avif} id="10"/>
                <img className="grid-photo" id="10" src={realizacje10} alt="" />
              </picture>
            </div>
          </div>
        </div>
      </StyledContainer>
      <StyledModal id="modal" className="Modal">
        <button onClick={hideModal}>
          <img src={close} alt="" />
        </button>
        <Swiper
          ref={swiperRef}
          modules={[Autoplay, Navigation]}
          navigation={true}
          grabCursor={true}
        >
          <SwiperSlide>
            {" "}
            <img src={realizacje1} alt="" />
          </SwiperSlide>
          <SwiperSlide>
            {" "}
            <img src={realizacje2} alt="" />
          </SwiperSlide>
          <SwiperSlide>
            {" "}
            <img src={realizacje3} alt="" />
          </SwiperSlide>
          <SwiperSlide>
            {" "}
            <img src={realizacje4} alt="" />
          </SwiperSlide>
          <SwiperSlide>
            {" "}
            <img src={realizacje5} alt="" />
          </SwiperSlide>
          <SwiperSlide>
            {" "}
            <img src={realizacje6} alt="" />
          </SwiperSlide>
          <SwiperSlide>
            {" "}
            <img src={realizacje7} alt="" />
          </SwiperSlide>
          <SwiperSlide>
            {" "}
            <img src={realizacje8} alt="" />
          </SwiperSlide>
          <SwiperSlide>
            {" "}
            <img src={realizacje9} alt="" />
          </SwiperSlide>
          <SwiperSlide>
            {" "}
            <img src={realizacje10} alt="" />
          </SwiperSlide>
        </Swiper>
      </StyledModal>
    </>
  );
};

export default GaleriaRealizacji;

const StyledModal = styled.div`
  display: none;
  position: fixed;
  height: 90vh;
  width: 100%;
  top: 0;
  left: 0;

  button {
    border: none;
    padding: 1rem;
    position: fixed;
    top: 20px;
    right: 20px;
    z-index: 5;
    background: #c9a227;
  }
`;

const StyledContainer = styled.div`
  .grid-galeria {
    display: grid;
    grid-template-columns: repeat(auto-fit, minmax(300px, 1fr));
    grid-gap: 1rem;

    cursor: pointer;
  }

  .header {
    display: flex;
    align-items: center;
    justify-content: space-between;
    margin-bottom: 5rem;

    button {
      border: none;
      text-decoration: none;
      color: #352f29;
      background: #c9a227;
      padding: 0.5em 1.25em;
      font-size: 1.5rem;
      font-weight: 700;
      cursor: pointer;
      transition: color 0.25s;
    }

    button:hover,
    button:focus {
      color: #c9a227;
      background: #352f29;
    }
  }
  .galeria-container {
    padding-top: 2rem;
    width: 90%;
    margin: 0 auto;
    .logo {
      width: 30rem;
    }
    .link {
      background: transparent;
    }
  }
  .galeria-wrapper {
    position: relative;
  }
  .galeria-wrapper::before {
    content: "";
    background-image: url(${backgroundImage});

    background-position: center;
    z-index: -1;
    position: absolute;
    top: 0px;
    bottom: 0px;
    left: 0px;
    right: 0px;
    opacity: 0.08;
  }

  .flex {
    display: flex;
    align-items: baseline;
    gap: 10px;
    margin-bottom: 2rem;
    h2 {
      color: #c9a227;
      font-size: 3rem;
    }
  }
  .line {
    width: 100%;

    border-bottom: 4px solid #c9a227;
  }
`;
