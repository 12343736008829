import styled from "styled-components";
import { Swiper, SwiperSlide } from "swiper/react";

import "swiper/css";
import "swiper/css/navigation";
import "../styles.css";

import { Autoplay, Navigation } from "swiper";
import osiowe2 from "../img/2-osiowe.png";
import osiowe3 from "../img/3-osiowe.png";
import osiowe4 from "../img/4-osiowe.png";
import gasienica from "../img/gasienica.png";
import koparkaKolowa from "../img/koparka-kolowa.png";
import koparkoLadowarka from "../img/koparko-ladowarka.png";
import mini from "../img/mini.png";
const Karuzela = () => {
  return (
    <StyledPanelContainer className="panel-container">
      <StyledContainer className="swiper-container-3">
        <Swiper
          navigation={true}
          modules={[Autoplay, Navigation]}
          className="swiper"
          slidesPerView={3}
          grabCursor={true}
          autoplay={true}
        >
          <SwiperSlide>
            <StyledCarouselElement className="carousel-element">
              <img src={osiowe2} alt="pojazdy samowyładowcze 2-osiowe (wywrotki)" title="pojazdy samowyładowcze 2-osiowe (wywrotki)" />
              <p>pojazdy samowyładowcze 2-osiowe </p>
            </StyledCarouselElement>
          </SwiperSlide>
          <SwiperSlide>
            <StyledCarouselElement className="carousel-element">
              <img src={osiowe3} alt="pojazdy samowyładowcze 3-osiowe (wywrotki)" title="pojazdy samowyładowcze 3-osiowe (wywrotki)" />
              <p>pojazdy samowyładowcze 3-osiowe </p>
            </StyledCarouselElement>
          </SwiperSlide>
          <SwiperSlide>
            <StyledCarouselElement className="carousel-element">
              <img src={osiowe4} alt="pojazdy samowyładowcze 4-osiowe (wywrotki)" title="pojazdy samowyładowcze 4-osiowe (wywrotki)" />

              <p>pojazdy samowyładowcze 4-osiowe</p>
            </StyledCarouselElement>
          </SwiperSlide>
          <SwiperSlide>
            <StyledCarouselElement className="carousel-element">
              <img className="gasienica" src={gasienica} alt="koparki gąsienicowe" title="koparki gąsienicowe"/>
              <p className="one-line">Koparki gąsienicowe</p>
            </StyledCarouselElement>
          </SwiperSlide>
          <SwiperSlide>
            <StyledCarouselElement className="carousel-element">
              <img src={koparkaKolowa} alt="koparki kołowe" title="koparki kołowe" />
              <p className="one-line">Koparki kołowe</p>
            </StyledCarouselElement>
          </SwiperSlide>
          <SwiperSlide>
            <StyledCarouselElement className="carousel-element">
              <img src={koparkoLadowarka} alt="koparko ładowarki" title="koparko ładowarki" />
              <p className="one-line">Koparko-ładowarki</p>
            </StyledCarouselElement>
          </SwiperSlide>
          <SwiperSlide>
            <StyledCarouselElement className="carousel-element">
              <img src={mini} alt="mini koparki" title="mini koparki" />
              <p className="one-line">Mini koparki</p>
            </StyledCarouselElement>
          </SwiperSlide>
        </Swiper>
      </StyledContainer>

      <StyledContainer className="swiper-container-1">
        <Swiper
          navigation={true}
          modules={[Autoplay, Navigation]}
          className="swiper"
          slidesPerView={1}
          grabCursor={true}
          autoplay={true}
        >
          <SwiperSlide>
            <StyledCarouselElement className="carousel-element">
              <img src={osiowe2} alt="pojazdy samowyładowcze 2-osiowe (wywrotki)" />
              <p>pojazdy samowyładowcze 2-osiowe </p>
            </StyledCarouselElement>
          </SwiperSlide>
          <SwiperSlide>
            <StyledCarouselElement className="carousel-element">
              <img src={osiowe3} alt="pojazdy samowyładowcze 3-osiowe (wywrotki)" />
              <p>pojazdy samowyładowcze 3-osiowe </p>
            </StyledCarouselElement>
          </SwiperSlide>
          <SwiperSlide>
            <StyledCarouselElement className="carousel-element">
              <img src={osiowe4} alt="pojazdy samowyładowcze 4-osiowe (wywrotki)" />

              <p>pojazdy samowyładowcze 4-osiowe</p>
            </StyledCarouselElement>
          </SwiperSlide>
          <SwiperSlide>
            <StyledCarouselElement className="carousel-element">
              <img className="gasienica" src={gasienica} alt="koparki gąsienicowe" />
              <p className="one-line">Koparki gąsienicowe</p>
            </StyledCarouselElement>
          </SwiperSlide>
          <SwiperSlide>
            <StyledCarouselElement className="carousel-element">
              <img src={koparkaKolowa} alt="koparki kołowe" />
              <p className="one-line">Koparki kołowe</p>
            </StyledCarouselElement>
          </SwiperSlide>
          <SwiperSlide>
            <StyledCarouselElement className="carousel-element">
              <img src={koparkoLadowarka} alt="koparko ładowarki" />
              <p className="one-line">Koparko-ładowarki</p>
            </StyledCarouselElement>
          </SwiperSlide>
          <SwiperSlide>
            <StyledCarouselElement className="carousel-element">
              <img src={mini} alt="mini koparki" />
              <p className="one-line">Mini koparki</p>
            </StyledCarouselElement>
          </SwiperSlide>
        </Swiper>
      </StyledContainer>
    </StyledPanelContainer>
  );
};

export default Karuzela;

const StyledContainer = styled.div`
  height: 50vh;
  width: 100%;

  @media (max-width: 768px) {
    height: 60vh;
  }
`;

const StyledCarouselElement = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  height: 300px;
  align-items: center;
  position: relative;

  p {
    position: absolute;
    bottom: -20%;
  }

  .one-line {
    position: absolute;
    bottom: -10%;
  }
`;

const StyledPanelContainer = styled.div`
  margin-top: -100px;
  .swiper-container-1 {
    display: none;
  }

  @media (max-width: 1000px) {
    .swiper-container-1 {
      display: block;
    }
    .swiper-container-3 {
      display: none;
    }
  }
`;
