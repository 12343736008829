import styled from "styled-components";
import logo from "../img/logo.PNG"
const Kontakt = () => {
  return (
    <>
      <section  id="kontakt">
        <StyledContainer className="kontakt-container">
          <div className="flex">
            <h2>KONTAKT</h2>
            <div className="line"></div>
          </div>
            <div className="grid-kontakt">
               <h3 className="siedziba">SIEDZIBA FIRMY</h3> 
               <div className="siedziba-border"></div>
               <p className="nazwa">Wo-Kaz</p>
               <p className="adres-miejscowosc">Warzno 84-208</p>
               <p className="adres-ulica">ul. Spokojna 1</p>
               <p className="nip">NIP: 5841365777 </p>
               <h3 className="dane-kontaktowe">DANE KONTAKTOWE</h3>
               <div className="kontakt-border"></div>
               <p className="numer">+48 796 177 742</p>
               <p className="email">wo-kaz@wp.pl</p>
               
            </div>
            <div className="logo-container">
            <img className="logo" width="400" height="300" src={logo} alt="logo przediębiorstwa wo-kaz usługi transportowe i prace ziemne" />
            </div>
         
        </StyledContainer>
      </section>
    </>
  );
};

export default Kontakt;

const StyledContainer = styled.div`
margin-top: 5rem;
.logo-container{
    width: 100%;
    display: flex;
    justify-content: center;
    margin-top: 5rem;
    padding-bottom: 3rem;
}
img.logo{
    width: 400px;
    height: auto;
}
h3{
    color: #fff;
    font-weight: 700;
    font-size: 2rem;
}

.siedziba-border, .kontakt-border{
    height: 5px;
    background: #c9a227;
}
  .grid-kontakt{
    display: grid;
    grid-template-columns: 1fr 1fr 1fr 1fr;
    margin-bottom: 3rem;
    grid-gap: .5rem 0;
   
    grid-template-areas: 
    "siedziba . kontakt ."
    "border-siedziba border-siedziba border-kontakt border-kontakt"
    "nazwa . numer . "
    "adres-miejscowosc . email. "
    "adres-ulica . . ."
    "nip . . .";
    p{
        margin: 0;
        padding: 0;
    }
    .siedziba-border {
        grid-area: border-siedziba;
        width: 80%;
    }
    .kontakt-border{
        grid-area: border-kontakt;
        width: 80%
    }
    .siedziba {
        grid-area: tytul,
    }
    .dane-kontaktowe {
        grid-area: kontakt;
    }
    .nazwa {
        grid-area: nazwa;
    }
    .adres-miejscowosc {
        grid-area: adres-miejscowosc;
    }
    .adres-ulica {
        grid-area: adres-ulica
    }
    .nip {
        grid-area: nip;
    }
    .numer{
        grid-area: numer;
    }
    .email {
        grid-area: email
    }
  }

  @media (max-width: 768px) {
    .grid-kontakt {
      display: flex;
      flex-direction: column;
      justify-content: center;
    }
    .dane-kontaktowe {
      margin-top: 2rem;
    }
  }

  .flex {
    display: flex;
    align-items: baseline;
    gap: 10px;
    margin-bottom: 4rem;
    h2 {
      color: #c9a227;
      font-size: 3rem;
    }
  }
  .line {
    width: 100%;

    border-bottom: 4px solid #c9a227;
  }
`;
