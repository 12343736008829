import { slide as Menu } from "react-burger-menu";
import React from "react";
import "./burger.css"
import { Link , animateScroll as scroll } from "react-scroll";

class BurgerMenu extends React.Component {
  state = {
    menuOpen: false,
  };

  handleStateChange(state) {
    this.setState({ menuOpen: state.isOpen });
  }

  closeMenu() {
    this.setState({ menuOpen: false });
  }

  render() {
   
    return (
      <Menu
        pageWrapId={"page-wrap"}
        isOpen={this.state.menuOpen}
        onStateChange={(state) => this.handleStateChange(state)}
      >
        <Link
          onClick={() => this.closeMenu()}
          className="menu-item"
          to="o-nas"
          smooth={true}
          duration={1000}
        >
          
          O nas
        </Link>
        <Link
          onClick={() => this.closeMenu()}
          className="menu-item"
          to="oferta"
          smooth={true}
          duration={1000}
        >
          {" "}
          Oferta
        </Link>
        <Link
          onClick={() => this.closeMenu()}
          className="menu-item"
          to="flota"
          smooth={true}
          duration={1000}
        >
          {" "}
          Flota
        </Link>
        <Link
          onClick={() => this.closeMenu()}
          className="menu-item"
          to="realizacje"
          smooth={true}
          duration={1000}
        >
          
          Realizacje
        </Link>
        <Link
          onClick={() => this.closeMenu()}
          className="menu-item"
          to="kontakt"
          smooth={true}
          duration={1000}
        >
          {" "}
          Kontakt
        </Link>
      </Menu>
    );
  }
}

export default BurgerMenu;


