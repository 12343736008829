import GlobalStyle from "./globalStyles";
import styled from "styled-components";
import arrowTop from "./img/arrow-top.svg"
//Router
import { Routes, Route } from "react-router-dom";
import { useEffect, useState } from "react";

import MainPage from "./pages/Main";
import GaleriaRealizacji from "./pages/GaleriaRealizacji";
import { Link } from "react-scroll";

function App() {
  
  const [showTopBtn, setShowTopBtn] = useState(false);
  useEffect(() => {
    window.addEventListener("scroll", () => {
      if (window.scrollY > 400) {
        setShowTopBtn(true);
      } else {
        setShowTopBtn(false);
      }
    });
  }, []);

 

  return (
    <div className="App" id="app">
      <GlobalStyle />

      {showTopBtn && (
        <Link to="app" smooth={true} duration={1000} >
        <StyledBtn id="button-scroll"><img src={arrowTop} alt="strzalka wskazujaca do góry" /></StyledBtn>
        </Link>
        
      )}
      <Routes>
        <Route path="/" element={<MainPage />} />
        <Route path="/galeria-realizacji" element={<GaleriaRealizacji />} />
      </Routes>
    </div>
  );
}

export default App;

const StyledBtn = styled.button`
  position: fixed;
  bottom: 20%;
  right: 10%;
  width: 50px;
  height: 50px;
  z-index: 100;
  border: none;
  border-radius: 50%;
  background: #c9a227;
`;
