import styled from "styled-components";
import { Link as LinkScroll, animateScroll as scroll } from "react-scroll";
const ONas = () => {
  return (
    <>
      <section>
        <StyledContainer className="o-nas-container" id="o-nas">
          <p>
            Oferujemy usługi w zakresie transportu i sprzedaży materiałów
            sypkich oraz wszelkich prac ziemnych. Posiadamy ponad 20 letnie
            doświadczenie.
          </p>
          <p>
            Do wszystkich klientów podchodzimy indywidualnie, a każdy projekt
            realizujemy kompleksowo z dużą dokładnością i w jak najkrótszym
            terminie. Nasze usługi kierujemy do osób prywatnych, jak i
            przedsiębiorstw.
          </p>

          <LinkScroll to="kontakt" smooth={true} duration={1000}>
            <button>Kontakt</button>
          </LinkScroll>
        </StyledContainer>
      </section>
    </>
  );
};

export default ONas;

const StyledContainer = styled.div`
  color: #fff;
  margin-bottom: 5rem;
  padding-top: 2rem;
  
  button{
    border: none;
    text-decoration: none;
    color: #352F29;
    background: #C9A227;
    padding: .5em 1.25em;
    font-size: 1.5rem;
    font-weight: 700;
    cursor: pointer;
    transition: color 0.25s;

  }

  button:hover, button:focus {
    color: #C9A227;
    background: #352F29;
    
    

  }

  p {
    font-size: 1.5rem;
    margin-bottom: 2em;
    line-height: 125%;
    letter-spacing: 2px;
  }

  
`;
